import { extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';

extend('required', {
    validate(value) {
      return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1
      };
    },
    computesRequired: true,
    message: '{_field_} needs to be filled out!'
  });

  extend('required_dd', value => {
    if (value > 0) {
      return true;
    }
    return 'Need a value for {_field_}!';
  });
  
  extend('email', email);

  extend('password', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'Password confirmation does not match'
  });