import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/store.js'
import { UserRoles, Permissions } from '../variables/variables.js'
import axios from "axios";


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('../views/gate/GateList.vue'), // Home
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/notpermitted',
            name: 'notpermitted',
            component: () => import('../views/common/permission/NotPermitted.vue'),
            meta: {
                authRequired: false,
            }
        },
        {
            path: '/passwordRecovery',
            name: 'passwordRecovery',
            component: () => import('../views/common/passwordRecovery/PasswordRecovery.vue'),
            props: true,
            meta: {
                authRequired: false,
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
            meta: {
                authRequired: false,
            }
        },
        {
            path: '/about',
            name: 'about',
            component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/installclient',
            name: 'Install',
            component: () => import('../views/Install.vue'),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/devices',
            name: 'devices',
            component: () => import('../views/device/DeviceList.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            },
        },
        {
            path: '/gates',
            name: 'gates',
            component: () => import('../views/gate/GateList.vue'),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/firmware',
            name: 'firmwareList',
            component: () => import('../views/firmware/FirmwareList.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin]
            }
        },
        {
            path: '/profile/:userId',
            name: 'profile',
            component: () => import('../views/user/UserProfile.vue'),
            props: route => ({
                userId: route.params.userId
            }),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('../views/user/UserList.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/user/editUser/:userId',
            name: 'editUser',
            component: () => import('../views/user/UserEdit.vue'),
            props: route => ({
                userId: route.params.userId
            }),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/user/registerUser',
            name: 'registerUser',
            component: () => import('../views/user/UserRegister.vue'),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/gate/editGate/:gateId',
            name: 'editGate',
            component: () => import('../views/gate/EditGate.vue'),
            props: route => ({
                gateId: route.params.gateId
            }),
            meta: {
                authRequired: true,
            },
        },
        {
            path: '/gateScheduleEdit/:gateScheduleId',
            name: 'gateScheduleEdit',
            component: () => import('../views/gateSchedule/GateScheduleEdit.vue'),
            props: route => ({
                gateScheduleId: route.params.gateScheduleId,
                gateId: route.params.gateId,
            }),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/gateInviteEdit/:gateInviteId',
            name: 'gateInviteEdit',
            component: () => import('../views/gateInvite/GateInviteEdit.vue'),
            props: route => ({
                gateInviteId: route.params.gateInviteId,
                gateId: route.params.gateId,
            }),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/device/editDevice/:deviceId',
            name: 'editDevice',
            component: () => import('../views/device/EditDevice.vue'),
            props: route => ({
                deviceId: route.params.deviceId
            }),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/firmware/editFirmware/:firmwareId',
            name: 'editFirmware',
            component: () => import('../views/firmware/EditFirmware.vue'),
            props: route => ({
                firmwareId: route.params.firmwareId
            }),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin]
            }
        },
        {
            path: '/localeStringResources',
            name: 'localeStringResources',
            component: () => import('../views/localeStringResource/LocaleStringResourceList.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/localeStringResource/:localeStringResourceId',
            name: 'localeStringResource',
            component: () => import('../views/localeStringResource/LocaleStringResourceEdit.vue'),
            props: route => ({
                localeStringResourceId: route.params.localeStringResourceId,
                languageId: route.params.languageId,
                languageName: route.params.languageName,
            }),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/log',
            name: 'logEntries',
            component: () => import('../views/log/LogEntries.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/reports',
            name: 'reports',
            component: () => import('../views/reports/Reports.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/log/viewLogEntry/:logId',
            name: 'viewLogEntry',
            component: () => import('../views/log/ViewLogEntry.vue'),
            props: route => ({
                logId: route.params.logId
            }),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/models',
            name: 'models',
            component: () => import('../views/model/ModelList.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/model/editModel/:modelId',
            name: 'editModel',
            component: () => import('../views/model/EditModel.vue'),
            props: route => ({
                modelId: route.params.modelId
            }),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/permissions',
            name: 'permissions',
            component: () => import('../views/security/Permissions.vue'),
            meta: {
                authRequired: true,
            }
        },
        {
            path: '/mailTemplate/editEmailAccount/:emailAccountId',
            name: 'editEmailAccount',
            component: () => import('../views/mailTemplate/EditEmailAccount.vue'),
            props: route => ({
                emailAccountId: route.params.emailAccountId
            }),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin]
            }
        },
        {
            path: '/mailTemplate/editMailTemplate/:mailTemplateId',
            name: 'editMailTemplate',
            component: () => import('../views/mailTemplate/EditMailTemplate.vue'),
            props: route => ({
                mailTemplateId: route.params.mailTemplateId
            }),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
            }
        },
        {
            path: '/mailTemplate/mailTemplateList',
            name: 'mailTemplateList',
            component: () => import('../views/mailTemplate/mailTemplateList.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
            }
        },
        {
            path: '/customer/customerList',
            name: 'customerList',
            component: () => import('../views/customer/CustomerList.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
            }
        },
        {
            path: '/customer/editCustomer/:customerId',
            name: 'customerEdit',
            component: () => import('../views/customer/CustomerEdit.vue'),
            props: route => ({
                customerId: route.params.customerId
            }),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
            }
        },
        {
            path: '/invoicing/invoiceList',
            name: 'invoiceList',
            component: () => import('../views/invoicing/InvoiceList.vue'),
            meta: {
                authRequired: true,
                permissions: [UserRoles.SuperAdmin, UserRoles.DemexAdmin],
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    const hasToken = store.getters['authentication/hasToken'];

    if (to.meta.authRequired == true) {
        if (!hasToken) {
            next({ name: 'login' })
        } else if (!store.getters['authentication/isAuthenticated']) {
            store.dispatch('authentication/getProfile').then(() => {
                if (!store.getters['authentication/isAuthenticated']) {
                    next({ name: 'login' })
                } else if (to.meta.permissions && to.meta.permissions.length > 0) {
                    store.dispatch('authentication/validatePermission', to.meta.permissions).then(authenticated => {
                        if (!authenticated) {
                            next('/notpermitted')
                        } else {
                            next();
                        }
                    });
                } else {
                    next();
                }
            });
        } else if (to.meta.permissions && to.meta.permissions.length > 0) {
            store.dispatch('authentication/validatePermission', to.meta.permissions).then(authenticated => {
                if (!authenticated) {
                    next('/notpermitted')
                } else {
                    next();
                }
            });
        } else {
            next();
        }
    } else {
        next();
    }

})



export default router
