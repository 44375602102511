
export const UserRoles = {
    RegularUser: 10,
    DemexAdmin: 20,
    SuperAdmin: 30,
    SystemUser: 99
}

export const GateRoles = {
    GateGuest: 10,
    GateUser: 20,
    GateInstaller: 30,
    GateOwner: 40
}

export const DayOfWeek = [

    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 0, name: 'Sunday' }
]
export const Permissions = {
    Operate: 310,
    Override: 320,
    RecieveNotices: 330,
    RecieveWarnings: 340,
    ViewCycles: 350,
    ViewStatus: 360,
    ViewLog: 370,
    ViewUsers: 380,
    ManageInfo: 390,
    ManageInvites: 400,
    ManageUsers: 410,
    ManageSchedules: 420,
    ManageUserPermissions: 430,
    ManageSubscription: 440,
}

export const logLevels = {
    10: { name: 'Debug', dark: false, background: '#ccc' },
    20: { name: 'Information', dark: false, background: '#ddd' },
    30: { name: 'Warning', dark: false, background: '#fa0' },
    40: { name: 'Error', dark: true, background: '#f33' },
    50: { name: 'Fatal', dark: true, background: '#000' },
}

// ToDo: It seems signalType is not used except for signalType.NotUsed. Figure out why and remove it --Martin
export const signalType = {
    NotUsed: 0,

    // Input signals    
    GateOpen: 20,
    GateClosed: 30,
    ClampProtectionClosing: 40,
    ClampProtectionOpening: 45,
    BatteryState: 50,
    EngineProtection: 60,

    // Output signals
    OpenGate: 100,
    CloseGate: 110,
    Detector: 120,
    ActiveForceOpen: 130,

    Custom: 1000,
}

export const signalDirection = {
    Input: 0,
    Output: 10,
}

export const firmwareUpdateStatus = {
    NotRequested: 0,
    Requested: 10,
    Started: 20,
    Failed: 30,
    Completed: 40,
}