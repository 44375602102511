import Vue from 'vue'
import VueI18n from 'vue-i18n'
import deviceService from '@/services/deviceService';
import axios from "axios";

Vue.use(VueI18n)

function loadLocaleMessages() {

    //setI18nLanguage(); commented out while making below code work
   
    const lang = localStorage.getItem('lang') || 'sv';
    const messages = {};
    messages[lang] = {};

    deviceService.getLocale({ Lang: lang })
        .then(res => {
            messages[lang] = unflatten(res.data);
        });
    return messages;
    
}

function unflatten(data) {
    let result = {}
    for (var i in data) {
        let keys = i.split('.')
        keys.reduce(function (r, e, j) {
            if (typeof r == "string") {
                console.error(`i18n: Missmatch in localization string interpolation. Unable to assign ${keys.join('.')}`);
                return result;
            }
            return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : [])
        }, result)
    }
    return result
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'sv',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'sv',
    messages: loadLocaleMessages(),
})


function setI18nLanguage(lang) {
    var currentLang = localStorage.getItem('lang') || 'en-GB';

    VueI18n.locale = currentLang
    axios.defaults.headers.common['Accept-Language'] = currentLang
    document.querySelector('html').setAttribute('lang', currentLang)
    return lang
}

