"use strict";

import axios from "axios";
import router from '@/router';
var baseControllerAddress = 'account/';

//(function () {
//    const authToken = localStorage.getItem('auth-token');
//    if (authToken) {
//        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

//    } else {
//        axios.defaults.headers.common['Authorization'] = null;

//    }
//})();

export default {
     sendMail: () => {
        return axios.post('/api/' + baseControllerAddress + "SendMail")
            .then(result => {
                alert('Mail sent');
            }).catch(function (error) {
                alert(error);
            });
    },
    post: (action, vm) => {
        return axios.post('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result;
            }).catch(function (error) {
                alert(error);
            });
    },
    get: (action) => {
        return axios.get('/api/' + baseControllerAddress + action)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                router.push('/login');
            });
    },
    getById: (action, id) => {
        return axios.get('/api/' + baseControllerAddress + action + "/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                router.push('/login');
            });
    },
    register: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "registerUser/", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    update: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "update/", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getAvailableUserRoles: () => {
        return axios.get('/api/' + baseControllerAddress + "GetAvalableUserRoles/")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    filterUserList: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "FilterUserList/", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    connectGate: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "ConnectGate/", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    removeConnectGate: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "removeConnectGate/", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    passwordRecoveryValidate: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "passwordRecoveryValidate", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    passwordRecoveryChangePassword: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "passwordRecoveryChangePassword", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    bulkRemoveUserGateMapping: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "bulkRemoveUserGateMapping/", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    resetInAppAuthenticationCode: (id) => {
        return axios.post('/api/' + baseControllerAddress + "resetInAppAuthenticationCode" + "/" + id)
            .then(result => {
                return result.data;
            });
    },
    deleteUser: (user) => {
        return axios.post('/api/' + baseControllerAddress + "delete" + "/", user)
            .then(result => {
                return result.data;
            });
    }
}