"use strict";

import axios from "axios";
var baseControllerAddress = 'device/';

export default {
    getLocale: (id) => {
        return axios.post('/api/' + 'locale/GetById/', id)
            .then(result => {
                return result;
            }).catch(function (error) {
                alert(error);
            });
    },
    getDeviceList: (action, vm) => {
        return axios.post('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    updateDevice: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "update", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
    requestFirmwareUpdate: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "requestFirmwareUpdate", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
    createDevice: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "create", vm)
            .catch(function (error) {
                //alert(error);
            });
    },
    getDevice: (id) => {
        return axios.get('/api/' + baseControllerAddress + "get/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
    getAllFreeDevices: () => {
        return axios.get('/api/' + baseControllerAddress + "getAllFreedevices")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                //alert(error);
            });
    },
    deleteAll: () => {
        return axios.delete('/api/' + baseControllerAddress + "deleteAllDevices")
            .catch(function (error) {
                //alert(error);
            });
    },
    deleteDevice: (id) => {
        return axios.delete('/api/' + baseControllerAddress + "deleteDevice/" + id)
            .catch(function (error) {
                //alert(error);
            });
    },
}