<template>
    <div>
        <dmx-title>Hem</dmx-title>
        <v-btn @click="sendMail()" color="#b00b69" style="color: white; ">skicka test-mejl</v-btn>
        <br /><br />...och här kommer ett bootstrap card
        <div>
            <b-card title="Card Title"
                    img-src="https://picsum.photos/600/300/?image=25"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2">
                <b-card-text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                </b-card-text>

                <b-button href="#" variant="primary">Go somewhere</b-button>
            </b-card>
        </div>
    </div>
</template>

<script>
    //importing vuex action to reference it below
    import { mapActions } from 'vuex';
    import accountService from '@/services/accountService';

    export default {
        name: 'home',
        components: {

        },
        data: () => ({
        }),
        methods: {
            sendMail() {
                accountService.sendMail();
            },
        },
        mounted() {

        }
    }
</script>