<template>
    <div id="app">
        <b-sidebar backdrop backdrop-variant="transparent" no-header v-if="isAuthenticated" id="side-collapse">
            <div class="sidebar">
                <div class="mt-2 mr-1 d-flex justify-content-end">
                    <b-icon role="button" icon="X" font-scale="1.5" v-b-toggle.side-collapse>close</b-icon>
                </div>
                <dmxSideMenu />
            </div>
        </b-sidebar>
        <div class="content">
            <b-navbar v-if="isAuthenticated" type="light" variant="gray">
                <b-navbar-brand role="button" v-b-toggle.side-collapse><b-icon icon="list"></b-icon></b-navbar-brand>
                <b-collapse id="nav-collapse" is-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item-dropdown right>
                            <!-- Using 'button-content' slot -->
                            <template #button-content>
                                <b-icon icon="person-lines-fill"></b-icon>
                            </template>
                            <b-dropdown-item>{{stateUser.firstName}} {{stateUser.lastName}}</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item @click="navigateToProfile"><b-icon icon="gear" class="mr-3"></b-icon> {{ $t('common_account') }}</b-dropdown-item>
                            <b-dropdown-item @click="doLogout()"><b-icon icon="box-arrow-right" class="mr-3"></b-icon> {{ $t('common_logout') }}</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
            <b-container fluid class="pt-4 pr-4 pb-4 pl-4">
                <router-view></router-view>
            </b-container>
        </div>
    </div>
</template>

<style>

    .sidebar {
        /*position: fixed;*/
        background-color: #003440;
        color: #dbd9c1 !important;
        /*top: 0px;*/
        /*left: 0px;*/
        height: 100vh;
        width: 320px;
        /*z-index: 1000;*/
        overflow-x: hidden;
        overflow-y: auto;
        padding-left: 24px;
    }

    .content {
        transition: margin 700ms;
    }

    #side-collapse.active ~ .content {
        margin-left: 320px;
    }

    /* top toolbar bg-color */
    .navbar {
        background-color: #b8c3c7 !important;
    }

</style>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import axios from "axios";
    import { PermissionTypes } from './variables/variables.js';
    import store from '@/store/store.js';
 
    import dmxSideMenu from './components/dmx-menu.vue';

    export default {
        name: 'App',
        components: {
            dmxSideMenu
        },
        data: () => ({
            sidebarVisible: true,
            title: '',
        }),
        methods: {
            navigateRouteItem(destination) {
                this.$router.push(destination);
            },
            hasPermission(e) {
                // kommer inte visas om man inte har permission typen på användaren
                return this.permissions.map(a => a).some(r => e.includes(r));
            },
            navigateToProfile() {
                this.$router.push({ name: 'editUser', params: { userId: parseInt(this.stateUser.id) } })
            },
            ...mapActions({
                // vuex module/Action name
                login: 'authentication/login',
                logout: 'authentication/logout',
                validatePermission: 'authentication/validatePermission',
            }),

            doLogout() {
                this.logout();
                this.$router.push('/login');
            },
            
            
        },
        computed: {
            ...mapState({
                stateUser: store => store.authentication.user,
            }),
            ...mapGetters({
                isAuthenticated: 'authentication/isAuthenticated',
            }), 
        },

        created() {
            this.$socket.start({
                log: false // Logging is optional but very helpful during development
            });
        },
        sockets: {
            // Defines needed for component listeners (methods can be empty) !?!
            GateStatusChange(data) { },
            GateScheduleChange(data) { },
        },

        watch: {
            isAuthenticated: {
                handler() {
                    if (this.isAuthenticated) {
                        this.$socket.invoke('signIn', this.stateUser.userGuid).then(response => {
                            //console.log(response);
                        });
                    }
                },
                immediate: true,
            }
        },
    };
</script>