import axios from "axios";

const setup = (store) => {
    axios.interceptors.request.use(
        (config) => {

            const token = store.getters['authentication/token']; //localStorage.getItem('auth-token');

            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (err) => {
            const originalConfig = err.config;

            if (originalConfig.url !== '/api/account/login' && err.response) {
                if (err.response.status == 401 && !originalConfig._retry) {
                    const oldToken = store.getters['authentication/token'];
                    const oldRefreshToken = store.getters['authentication/refreshToken'];

                    originalConfig._retry = true;
                    try {
                        const res = await axios.post('/api/account/refreshtoken', {
                            accessToken: oldToken, 
                            refreshToken: oldRefreshToken
                        });
                        const { accessToken, refreshToken } = res.data;

                        store.commit('authentication/setToken', accessToken);
                        store.commit('authentication/setRefreshToken', refreshToken);
                        return axios(originalConfig);
                    } catch (_err) {
                        return Promise.reject(_err);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};

export default setup;