import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                grey: '#b8c3c7',
                darker_grey: '#8f8f93',
                accent: '#8c9eff',
                error: '#b71c1c',
                success: '#519d3e',
                demex_primary: '#003440',
                demex_secondary: '#8f8f93',
                demex_yellow: '#f3f2e8'
            },
        },
    },
});
