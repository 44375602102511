<template>
    <div>
        <div v-if="backlink">
            <b-link @click="goBack()"><b-icon icon="arrow-left-short" font-scale="2"></b-icon></b-link>
        </div>
        <div class="bread-crumb">
            <b-link to="/" :class="!bcItems ? 'active' : ''">Home</b-link>
            <template v-if="bcItems" v-for="(bc, i) in bcItems">
                » <b-link :to="bc.href" :class="bc.active ? 'active' : ''">{{ bc.text }}</b-link>
            </template>
        </div>
        <h1><slot></slot></h1>
    </div>
</template>

<style scoped>
    h1 {
        text-transform: uppercase;
        font-weight: bold;
    }

    .bread-crumb {
        text-transform: uppercase;
        font-size: 12px;
        color: #003440;
    }

    .active {
        font-weight: bold;
    }
</style>

<script>
    import router from '@/router';
    export default {
        props: {
            backlink: Boolean,
            bcItems: Array
        },
        methods: {
            goBack() {
                router.go(-1)
            }
        }
    }
</script>